<template>
  <div v-loading="loading">
    <el-tooltip :content="fieldPlaceholder" placement="top-start">
    <el-select
      v-model="selectedFileds[filter]"
      @change="updateEntityData"
      clearable
      filterable
      multiple
      collapse-tags
      :placeholder="fieldPlaceholder"
      size="mini"
    >
      <el-option
        value="ALL"
        label="All"
        v-if="allEntityDataList && allEntityDataList.length"
      >
      </el-option>
      <el-option
        v-for="(temp, i) in allEntityDataList"
        :key="temp._id + '_' + i"
        :value="temp._id"
        :label="getLabel(temp, i)"
        >{{ getLabel(temp, i) }}
      </el-option>
    </el-select>
  </el-tooltip>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  props: ["entityId", "fieldPlaceholder", "filter", "selectedFileds", "fromCD"],
  computed: {
    ...mapGetters("entities", [
      "getEntityDataByEntityId",
      "getEntityById",
      "getEntityRecordsForTable",
      "getEntitiesDataErrors",
    ]),
  },
  watch: {},
  data() {
    return {
      currentEntity: null,
      loading: false,
      allEntityDataList: [],
      selected_filter: "",
    };
  },
  async mounted() {
    this.loading = true;
    if (
      !this.selectedFileds[this.filter] ||
      !this.selectedFileds[this.filter].length
    ) {
      // this.selectedFileds[this.filter] = ["ALL"];
    }
    await this.fetchEntityById(this.entityId);
    this.loading = false;
  },
  methods: {
    updateEntityData(value) {
      console.log("value", value)
      if (value.indexOf("ALL") != -1 && value.length > 1) {
        let i = value.indexOf("ALL");
        this.selectedFileds[this.filter].splice(i, 1);
        let v = this.selectedFileds[this.filter];
        this.$emit("entityFilterUpdated", {
          v,
          filter: this.filter,
        });
      } else {
        this.$emit("entityFilterUpdated", {
          value,
          filter: this.filter,
        });
      }
    },
    async fetchEntityById(entityId) {
      await this.$store.dispatch("entities/fetchEntityByIdForEntityField", {
        entity_id: entityId,
      });
      if (this.getEntityById) {
        this.currentEntity = this.getEntityById;
        this.fetchEntitiesDataForTable(entityId);
      } else {
        this.loading = false;
      }
    },
    async fetchEntitiesDataForTable(entityId) {
      this.loading = true;
      let params = {
        entity_id: entityId,
        template_fields_data: this.currentEntity.templates.map((template) => {
          return {
            template_id: template.template_id,
          };
        }),
        filters: [],
      };
      await this.$store.dispatch("entities/fetchEntityRecordsForTable", params);
      if (this.getEntityRecordsForTable) {
        this.allEntityDataList = this.getEntityRecordsForTable.data;
        this.loading = false;
      } else {
        this.loading = false;
      }
    },
    getLabel(item, i) {
      let selectedEntity = this.currentEntity ? this.currentEntity : {};
      let name =
        item &&
        item.entity_prime_data &&
        Object.keys(item.entity_prime_data) &&
        item.entity_prime_data[Object.keys(item.entity_prime_data)[0]]
          ? item.entity_prime_data[Object.keys(item.entity_prime_data)[0]]
          : "data " + i;
      if (selectedEntity && selectedEntity.primaryFields) {
        let primaryField = selectedEntity.primaryFields[0];
        let key =
          primaryField && primaryField["key"] ? primaryField["key"] : null;
        if (key) {
          if (item.entity_prime_data && item.entity_prime_data[key]) {
            name = item.entity_prime_data[key];
          } else {
            name =
              item.entityData &&
              primaryField.template_id &&
              item.entityData[primaryField.template_id] &&
              item.entityData[primaryField.template_id][key]
                ? item.entityData[primaryField.template_id][key]
                : "data" + i;
          }
        }
      }
      return name;
    },
  },
};
</script>
